<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      CRS Manager
      <v-spacer></v-spacer>
      <v-btn @click="logout()" v-if="loggedIn">Logout</v-btn>
      <v-btn @click="login()" v-if="!loggedIn">Login</v-btn>
    </v-app-bar>

    <v-main>
      <v-row>
        <v-col cols="3">
          <v-navigation-drawer permanent>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Manager Mockup
                </v-list-item-title>
                <v-list-item-subtitle> v0.1 </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav>
              <v-list-group :value="false" prepend-icon="mdi-xml">
                <template v-slot:activator>
                  <v-list-item-title>CRS Manager</v-list-item-title>
                </template>

                <v-list-item
                  v-for="c in channels"
                  :key="c.id"
                  link
                  :to="`/channel/${c.id}`"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-chemical-weapon</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    >{{ c.name }} ({{ c.id }})</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col cols="6">
          <router-view />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">User Login</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Username*" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Password*"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="logout()">
                Logout
              </v-btn>
              <v-btn color="green darken-1" text @click="login()">
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "ApiMock",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    login() {
      this.$store.dispatch("changeLoginState", true);
      this.$router.replace({
        name: "channel",
        params: { id: this.$route.params.id },
        query: { auth: true },
      });
    },
    logout() {
      this.$store.dispatch("changeLoginState", false);
      this.$router.replace({
        name: "channel",
        params: { id: this.$route.params.id },
        query: {},
      });
    },
  },
  computed: {
    channels() {
      return this.$store.getters.channels;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>
