<template>
  <v-container>
    <v-row justify="center">
      <p class="text-h3 py-5">{{ header }}</p>
      <v-expansion-panels multiple v-model="panelsOpen">
        <v-expansion-panel>
          <v-expansion-panel-header>Channel Settings</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="text-right py-5">
              <v-btn
                outlined
                :color="channel.enabled ? 'error' : 'success'"
                @click="toggle(channel)"
                width="100%"
                >{{ channel.enabled ? "Disable" : "Enable" }} Channel</v-btn
              >
            </p>
            <ChannelConfig :channelConfig="channelConfig" />
            <v-row dense>
              <v-spacer></v-spacer>
              <v-btn class="mr-5">Cancel</v-btn>
              <v-btn color="primary" @click="close(0)">Save</v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Master Credentials</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row dense v-for="(cred, index) in channelCred" :key="index">
              <v-col cols="4">{{ cred.name }}</v-col>
              <v-col cols="7">
                <v-text-field
                  dense
                  class="formfields"
                  :value="cred.value"
                  :disabled="cred.readonly && !loggedIn"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-icon v-if="cred.readonly && !loggedIn">mdi-lock</v-icon>
              </v-col>
            </v-row>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-btn class="mr-5">Cancel</v-btn>
              <v-btn color="primary" @click="close(1)">Save</v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-col> Sites </v-col>
            <v-col class="text-right">
              <v-btn
                outlined
                color="success"
                @click.stop="dialog = true"
                small
                class="pl-auto"
                >Add Site</v-btn
              >
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              label="Search"
              v-model="searchTerm"
              clearable
            ></v-text-field>
            <v-simple-table class="mx-0">
              <transition-group name="fade" tag="tbody">
                <tr v-for="site in channelSites" :key="site.id">
                  <td class="text-right pl-2 ma-0">
                    <v-switch
                      dense
                      v-model="site.active"
                      :color="getColor(site.active)"
                      @change="confirm(site)"
                    ></v-switch>
                  </td>
                  <td class="text-left py-4 pl-0">
                    <span class="text-caption pr-2">#{{ site.id }}</span>
                    <v-badge
                      right
                      :content="`Tier ${site.tier}`"
                      inline
                      tile
                      :color="getColor(site.tier)"
                    >
                      <span>{{ site.name }}</span>
                    </v-badge>
                  </td>
                  <td class="text-right pr-2 ma-0">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="grey"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="edit(site)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="red darken-4"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="remove(channel, site)"
                        >
                          mdi-close
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </transition-group>
            </v-simple-table>
            <v-row dense>
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <ConfirmDialog ref="confirm" />
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Site</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="10">
                <v-autocomplete
                  dense
                  :items="dummySites"
                  label="Site"
                  item-text="id"
                  :filter="customFilter"
                  v-model="siteID"
                >
                  <template v-slot:item="data">
                    {{ data.item.id }} - {{ data.item.name }}
                  </template>
                  <template v-slot:selection="data">
                    {{ data.item.id }} - {{ data.item.name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-select
                  dense
                  :items="[1, 2, 3]"
                  label="Tier"
                  v-model="siteTier"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  :items="['Upper Connector', 'CRS Connector']"
                  label="Connector"
                  v-model="siteConnector"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="overwriteCred"
                  label="Overwrite Credentials"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" v-if="overwriteCred">
                <v-text-field
                  label="Username"
                  dense
                  v-model="siteUser"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="overwriteCred">
                <v-text-field
                  label="Password"
                  dense
                  v-model="sitePassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="overwriteConfig"
                  label="Overwrite Config"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" v-if="overwriteConfig">
                <ChannelConfig :channelConfig="channelConfig" />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelSite()">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveSite()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.formfields {
  padding: 0;
  margin: 0;
}
</style>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import ChannelConfig from "./ChannelConfig.vue";
export default {
  name: "ChannelView",
  components: {
    ConfirmDialog,
    ChannelConfig,
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.id === from.params.id) return false;
    this.updateData(to.params.id);
    next();
  },
  created() {
    this.updateData(this.$route.params.id);
  },
  computed: {
    header() {
      return `${this.channelName} (${this.channelNo})`;
    },
    channelSites() {
      return this.$store.getters.getSites(this.channelNo).filter((s) => {
        if (!this.searchTerm) return true;
        if (s.id.toString().includes(this.searchTerm)) return true;
        if (s.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
          return true;
        return false;
      });
    },
    channelCred() {
      return this.$store.getters.getCredentials(this.channelNo);
    },
    channel() {
      return this.$store.getters.getChannel(this.channelNo);
    },
    channelConfig() {
      return this.$store.getters.getConfiguration(this.channelNo);
    },
    channelName() {
      return this.$store.getters.getChannelName(this.channelNo);
    },
    dummySites() {
      return this.$store.getters.dummySites;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    saveSite() {
      this.$store.dispatch("addSite", {
        id: this.siteID,
        channelID: this.channelNo,
        connector: this.siteConnector,
        name: this.dummySites.filter((s) => s.id === this.siteID)[0].name,
        tier: this.siteTier,
        active: false,
        config: {
          credentials: !this.overwriteCred
            ? null
            : {
                username: this.siteUser,
                password: this.sitePassword,
              },
        },
      });
      // TODO this.siteID = seachField
      this.searchTerm = this.siteID.toString();
      this.cancelSite();
    },
    cancelSite() {
      this.dialog = false;
      this.overwriteCred = false;
      this.overwriteConfig = false;
      this.siteID = "";
      this.sitePassword = "";
      this.siteUser = "";
      this.siteConnector = "";
    },
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.id.toString();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    async remove(channel, site) {
      const confirm = await this.$refs.confirm.open(
        "Confirm",
        `Are you sure that you would like to remove Site ${site.id} (${site.name}) from this channel?`
      );

      if (confirm) {
        this.$store.dispatch("removeSite", {
          channelID: channel.id,
          siteID: site.id,
        });
      }
    },
    async confirm(site) {
      const confirm = await this.$refs.confirm.open(
        "Confirm",
        `Are you sure you would like to ${
          site.active ? "activate" : "deactivate"
        } this site?`
      );
      if (!confirm) {
        site.active = !site.active;
      }
    },
    getColor(tier) {
      if (typeof tier == "boolean") {
        switch (tier) {
          case true:
            return "success";
        }
      }

      switch (tier) {
        case 1:
          return "rgb(0, 128 ,0)";
        case 2:
          return "rgb(200, 200, 0)";
        case 3:
          return "rgb(225, 150, 0)";
      }
    },
    close(panelNo) {
      this.panelsOpen = this.panelsOpen.filter((p) => p !== panelNo);
    },
    async toggle(channel) {
      const confirm = await this.$refs.confirm.open(
        "Confirm",
        `Are you sure you would like to ${
          channel.enabled ? "disable" : "enable"
        } this channel?`
      );
      if (!confirm) {
        channel.enabled = !channel.enabled;
      }
      channel.enabled = !channel.enabled;
    },
    updateData(id) {
      this.channelNo = id;
    },
  },
  data() {
    return {
      dialog: false,
      panelsOpen: [],
      channelNo: null,
      overwriteCred: false,
      overwriteConfig: false,
      siteID: "",
      sitePassword: "",
      siteUser: "",
      siteConnector: "",
      siteTier: 3,
      searchTerm: "",
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>