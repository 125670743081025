<template>
  <v-container fluid>
    <v-row dense v-for="(config, index) in channelConfig" :key="index">
      <v-col cols="4"
        ><p class="text-caption">{{ config.name }}</p></v-col
      >
      <v-col cols="7">
        <v-text-field
          dense
          :value="config.value"
          :disabled="config.readonly && !loggedIn"
          v-if="config.type === 'text'"
          class="formfields"
        ></v-text-field>
        <v-checkbox
          dense
          color="primary"
          v-model="config.value"
          :disabled="config.readonly && !loggedIn"
          v-if="config.type === 'checkbox'"
          class="formfields"
        ></v-checkbox>
        <v-select
          dense
          :items="config.options"
          v-if="config.type === 'select'"
          :value="config.value"
          class="formfields"
        ></v-select>
        <v-combobox
          dense
          v-if="config.type === 'combo'"
          multiple
          small-chips
          v-model="config.value"
          class="formfields"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-bind="attrs" :input-value="selected" label small>
              <span class="pr-2">
                {{ item }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
      <v-col>
        <v-icon v-if="config.readonly && !loggedIn">mdi-lock</v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ChannelConfig",
  props: ["channelConfig"],
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>