import Vue from 'vue';
import Vuex from 'vuex';
import { faker } from '@faker-js/faker';

Vue.use(Vuex);

const sitesOne = [];
for (let i = 0; i < 12; i += 1) {
  sitesOne.push({
    id: faker.datatype.number({ min: 9361, max: 13482 }),
    connector: faker.helpers.arrayElement(['CRS Connector', 'Upper Connector']),
    name: `${faker.commerce.productName()} ${faker.helpers.arrayElement([
      'Hotel',
      'Resort',
      'Apartments',
      'Plaza',
      'Inn',
      'Mansion',
      'Lodge',
    ])}`,
    tier: faker.datatype.number({ min: 1, max: 3 }),
    active: faker.datatype.boolean(),
  });
}

const sitesTwo = [];
for (let i = 0; i < 8; i += 1) {
  sitesTwo.push({
    id: faker.datatype.number({ min: 9361, max: 13482 }),
    connector: faker.helpers.arrayElement(['CRS Connector', 'Upper Connector']),
    name: `${faker.commerce.productName()} ${faker.helpers.arrayElement([
      'Hotel',
      'Resort',
      'Apartments',
      'Plaza',
      'Inn',
      'Mansion',
      'Lodge',
    ])}`,
    tier: faker.datatype.number({ min: 1, max: 3 }),
    active: faker.datatype.boolean(),
  });
}

const dummySites = [];
for (let i = 0; i < 200; i += 1) {
  dummySites.push({
    id: faker.datatype.number({ min: 9361, max: 13482 }),
    connector: faker.helpers.arrayElement(['CRS Connector', 'Upper Connector']),
    name: `${faker.commerce.productName()} ${faker.helpers.arrayElement([
      'Hotel',
      'Resort',
      'Apartments',
      'Plaza',
      'Inn',
      'Mansion',
      'Lodge',
    ])}`,
    tier: faker.datatype.number({ min: 1, max: 3 }),
    active: faker.datatype.boolean(),
  });
}

export default new Vuex.Store({
  state: {
    loggedIn: false,
    dummyData: {
      sites: dummySites.sort((a, b) => (a.id > b.id ? 1 : -1)),
    },
    channels: [
      {
        id: '26',
        name: 'Agoda',
        link: 'agoda',
        enabled: true,
        credentials: [
          { name: 'User', value: 'TestUser', readonly: true },
          { name: 'Password', value: 'h4Q.3t%2yP', readonly: true },
          { name: 'Url', value: 'https://pushurl.net', readonly: false },
        ],
        configuration: [
          {
            name: 'Handler',
            value: 'YCS5',
            type: 'text',
            readonly: true,
          },
          {
            name: 'PublishMode',
            value: 'AR',
            type: 'text',
            readonly: true,
          },
          {
            name: 'PublishDataMode',
            value: 'Data',
            type: 'text',
            readonly: true,
          },
          {
            name: 'GroupedPublish',
            value: false,
            type: 'checkbox',
            readonly: true,
          },
          {
            name: 'ChM MinLOS',
            value: true,
            type: 'checkbox',
            readonly: true,
          },
          {
            name: 'API Version',
            value: 2,
            type: 'select',
            readonly: false,
            options: [1, 2],
          },
          {
            name: 'Retrieve Bookings',
            value: true,
            type: 'checkbox',
            readonly: false,
          },
          {
            name: 'Disable Rate Update',
            value: false,
            type: 'checkbox',
            readonly: false,
          },
          {
            name: 'Disable Avail Update',
            value: false,
            type: 'checkbox',
            readonly: false,
          },
          {
            name: 'Error Emails',
            value: ['test@profitroom.com'],
            type: 'combo',
            readonly: false,
          },
        ],
        sites: sitesOne,
      },
      {
        id: '58',
        name: 'Airbnb',
        link: 'airbnb',
        enabled: true,
        credentials: [
          { name: 'User', value: 'User', readonly: true },
          { name: 'Password', value: 'Password1234', readonly: true },
          { name: 'Url', value: 'https://pushurl2.net', readonly: false },
        ],
        configuration: [
          {
            name: 'PublishMode',
            value: 'AR',
            type: 'text',
            readonly: true,
          },
          {
            name: 'GroupedPublish',
            value: false,
            type: 'checkbox',
            readonly: true,
          },
          {
            name: 'ChM MinLOS',
            value: true,
            type: 'checkbox',
            readonly: true,
          },
          {
            name: 'API Version',
            value: 2,
            type: 'select',
            readonly: false,
            options: [1, 2],
          },
          {
            name: 'Retrieve Bookings',
            value: true,
            type: 'checkbox',
            readonly: false,
          },
          {
            name: 'Disable Rate Update',
            value: false,
            type: 'checkbox',
            readonly: false,
          },
          {
            name: 'Disable Avail Update',
            value: false,
            type: 'checkbox',
            readonly: false,
          },
          {
            name: 'Error Emails',
            value: ['test@profitroom.com'],
            type: 'combo',
            readonly: false,
          },
        ],
        sites: sitesTwo,
      },
    ],
  },
  getters: {
    channels(state) {
      return state.channels;
    },
    getChannelName: (state) => (id) => {
      return state.channels.filter((c) => c.id === id)[0].name || '';
    },
    getChannel: (state) => (id) => {
      return state.channels.filter((c) => c.id === id)[0] || {};
    },
    getConfiguration: (state) => (id) => {
      return state.channels.filter((c) => c.id === id)[0].configuration || [];
    },
    getCredentials: (state) => (id) => {
      return state.channels.filter((c) => c.id === id)[0].credentials || {};
    },
    getSites: (state) => (id) => {
      return (
        state.channels
          .filter((c) => c.id === id)[0]
          .sites.sort((a, b) => (a.id > b.id ? 1 : -1)) || {}
      );
    },
    dummySites(state) {
      return state.dummyData.sites;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
  },
  mutations: {
    removeSite(state, payload) {
      const channels = state.channels;
      channels.forEach((c, i) => {
        if (c.id === payload.channelID) {
          channels[i].sites = channels[i].sites.filter(
            (s) => s.id !== payload.siteID
          );
        }
      });
      Vue.set(state, 'channels', channels);
    },
    addSite(state, site) {
      state.channels.forEach((c, i) => {
        if (c.id === site.channelID) {
          state.channels[i].sites.push({
            id: site.id,
            connector: site.connector,
            name: site.name,
            tier: site.tier,
            active: site.active,
          });
        }
      });
    },
    changeLoginState(state, payload) {
      state.loggedIn = payload;
    },
  },
  actions: {
    removeSite({ commit }, payload) {
      commit('removeSite', payload);
    },
    addSite({ commit }, payload) {
      commit('addSite', payload);
    },
    changeLoginState({ commit }, payload) {
      commit('changeLoginState', payload);
    },
  },
  modules: {},
});
